import gql from 'graphql-tag';

/* ********* User related mutations START ***************/

export const COMPANYADMIN_ALLOW = gql`
  mutation($TeamID: String!, $Email: String!) {
    allowCompanyAdmin(TeamID: $TeamID, Email: $Email) {
      successMessage
    }
  }
`;

export const COMPANYADMIN_REVOKE = gql`
  mutation($TeamID: String!, $Email: String!) {
    revokeCompanyAdmin(TeamID: $TeamID, Email: $Email) {
      successMessage
    }
  }
`;

export const SUPERADMIN_ALLOW = gql`
  mutation($TeamID: String!, $Email: String!) {
    allowSuperAdmin(TeamID: $TeamID, Email: $Email) {
      successMessage
    }
  }
`;

export const SUPERADMIN_REVOKE = gql`
  mutation($TeamID: String!, $Email: String!) {
    revokeSuperAdmin(TeamID: $TeamID, Email: $Email) {
      successMessage
    }
  }
`;

export const ADD_MEMBER = gql`
  mutation($addmemberDetails: MemberDetailReq!) {
    addTeamMember(memberDetails: $addmemberDetails) {
      successMessage
      data
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation($securityPrincipleID: String!, $updateProfileReq: UpdateProfileReq!) {
    updateProfile(securityPrincipleID: $securityPrincipleID, updateProfileReq: $updateProfileReq) {
      successMessage
      data
      code
      content
    }
  }
`;

export const ReInvite_Member = gql`
  mutation($teamInviteReq: TeamInviteReq!) {
    reInviteMember(teamInviteReq: $teamInviteReq) {
      successMessage
      data
    }
  }
`;

export const CHANGE_MEMBER_STATUS = gql`
  mutation($changeMemberStatusReq: TeamInviteReq!, $IsDisabled: Boolean!) {
    changeMemberStatus(changeMemberStatusReq: $changeMemberStatusReq, IsDisabled: $IsDisabled) {
      successMessage
      data
    }
  }
`;

export const CHANGE_READONLY_STATUS = gql`
  mutation($changeMemberStatusReq: TeamInviteReq!, $status: Boolean!) {
    changeReadOnlyStatus(changeMemberStatusReq: $changeMemberStatusReq, status: $status) {
      successMessage
      data
    }
  }
`;

export const CHANGE_MEMBER_STATUS_SA = gql`
  mutation($changeMemberStatusReq: TeamInviteReq!) {
    changeMemberStatusSA(changeMemberStatusReq: $changeMemberStatusReq) {
      successMessage
      data
    }
  }
`;

export const UPDATE_BILLING_ADDR = gql`
  mutation($inputs: CBBillingAddr!) {
    updateBillingAddr(inputs: $inputs) {
      successMessage
      data
      content
    }
  }
`;

export const SWITCH_USER = gql`
  mutation($orgId: Float!, $adId: Float!, $adGroupId: Float!, $campaignId: Float!) {
    switchUser(orgId: $orgId, adId: $adId, adGroupId: $adGroupId, campaignId: $campaignId) {
      successMessage
      searchAdsErrors
    }
  }
`;

/* ********* User related mutations END ***************/

export const CAMPAIGN_DRAFT = gql`
  mutation($campaignDraft: CampaignDraft!, $draftId: String) {
    campaignDraft(campaignDraft: $campaignDraft, draftId: $draftId) {
      successMessage
      data
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation($orgId: Float!, $campaignRequest: CampaignDraft!) {
    createCampaign(orgId: $orgId, campaignRequest: $campaignRequest) {
      successMessage
      data
      searchAdsErrors
    }
  }
`;

export const DELETE_INTEGRATION = gql`
  mutation($identifier: String!, $TeamID: String!, $type: String!) {
    deleteIntegration(identifier: $identifier, TeamID: $TeamID, type: $type) {
      successMessage
      data
      code
    }
  }
`;

export const DELETE_MMP_INTEGRATION = gql`
  mutation($mmpId: String!, $appId: Float!) {
    deleteAttrIntegration(mmpId: $mmpId, appId: $appId) {
      successMessage
      code
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation($orgId: Float!, $campaignId: Float!, $campaignRequest: CampaignUpdateReq!) {
    updateCampaign(orgId: $orgId, campaignId: $campaignId, campaignRequest: $campaignRequest) {
      successMessage
      data
      searchAdsErrors
    }
  }
`;

export const TOGGLE_ADGROUP = gql`
  mutation($orgId: Float!, $campaignId: Float!, $adgroupId: Float!, $adGroupStatus: AdGroupStatusChange!) {
    enableDisableAdGroup(orgId: $orgId, campaignId: $campaignId, adgroupId: $adgroupId, adGroupStatus: $adGroupStatus) {
      successMessage
      data
    }
  }
`;

export const TOGGLE_CREATIVE_SET = gql`
  mutation($orgId: Float!, $enablePauseCreativeSetReq: ChangeCreativeSetStatusReq!) {
    enablePauseCreativeSet(orgId: $orgId, enablePauseCreativeSetReq: $enablePauseCreativeSetReq) {
      successMessage
      data
    }
  }
`;

export const ADD_KEYWORDS = gql`
  mutation($addKeywords: AddKeywordsReq!) {
    addKeywords(addKeywords: $addKeywords) {
      successMessage
      data
      searchAdsErrors
    }
  }
`;

export const UPDATE_ADGROUP = gql`
  mutation(
    $orgId: Float!
    $campaignId: Float!
    $adgroupId: Float!
    $adgroupRequest: AdGroupUpdateReq!
    $appDownloadersValue: Float!
    $selectedLocalities: [populateLocality!]!
  ) {
    updateAdGroup(
      orgId: $orgId
      campaignId: $campaignId
      adgroupId: $adgroupId
      adgroupRequest: $adgroupRequest
      appDownloadersValue: $appDownloadersValue
      selectedLocalities: $selectedLocalities
    ) {
      successMessage
      data
      searchAdsErrors
    }
  }
`;

export const TOGGLE_CAMPAIGNS = gql`
  mutation($orgId: Float!, $campaignId: Float!, $campaignStatus: CampaignStatusChange!) {
    enableDisableCampaign(orgId: $orgId, campaignId: $campaignId, campaignStatus: $campaignStatus) {
      successMessage
      data
    }
  }
`;

export const ADD_CREATIVE_SET = gql`
  mutation($orgId: Float!, $adgroupId: Float!, $campaignId: Float!, $creativeSet: CreativeSet!) {
    addCreativeSet(orgId: $orgId, adgroupId: $adgroupId, campaignId: $campaignId, creativeSet: $creativeSet) {
      successMessage
      data
      searchAdsErrors
    }
  }
`;

export const ADD_ADGROUP = gql`
  mutation($orgId: Float!, $adgroupReq: AdGroupReq!) {
    addAdgroup(orgId: $orgId, adgroupReq: $adgroupReq) {
      successMessage
      data
      searchAdsErrors
    }
  }
`;

export const ADD_NEGATIVE_KEYWORD_TO_ADGROUP = gql`
  mutation($addNegativeKeywords: AddNegativeKeywordsReqForAdgroup!) {
    addNegativeKeywordsInAdGroup(addNegativeKeywords: $addNegativeKeywords) {
      successMessage
      data
      searchAdsErrors
    }
  }
`;

export const ADD_NEGATIVE_KEYWORD_TO_CAMPAIGN = gql`
  mutation($addNegativeKeywords: AddNegativeKeywordsReqForCampaign!) {
    addNegativeKeywordsInCampaign(addNegativeKeywords: $addNegativeKeywords) {
      successMessage
      data
      searchAdsErrors
    }
  }
`;

export const CHANGE_KEYWORD_BID_AMOUNT = gql`
  mutation($orgId: Float!, $campaignId: Float!, $adGroupId: Float!, $keywordBidAmount: KeywordBidAmountReq!) {
    changeKeywordBidAmount(orgId: $orgId, campaignId: $campaignId, adGroupId: $adGroupId, keywordBidAmount: $keywordBidAmount) {
      successMessage
      data
      searchAdsErrors
    }
  }
`;

export const UPDATE_CREATIVE_SET = gql`
  mutation($orgId: Float!, $creativeSetId: Float!, $creativeSetUpdateReq: CreativeSetUpdateReq!) {
    updateCreativeSet(orgId: $orgId, creativeSetId: $creativeSetId, creativeSetUpdateReq: $creativeSetUpdateReq) {
      successMessage
      data
      searchAdsErrors
    }
  }
`;

export const TARGET_KEYWORD_STATUS = gql`
  mutation($orgId: Float!, $changeTargetKeyStatusReq: ChangeTargetKeyStatusReq!) {
    changeTargetKeyStatus(orgId: $orgId, changeTargetKeyStatusReq: $changeTargetKeyStatusReq) {
      successMessage
      data
    }
  }
`;

export const DELETE_CAMPAIGN = gql`
  mutation($orgId: Float!, $campaignId: Float!) {
    deleteCampaign(orgId: $orgId, campaignId: $campaignId) {
      successMessage
    }
  }
`;

export const DELETE_ADGROUP = gql`
  mutation($orgId: Float!, $adgroupId: Float!, $campaignId: Float!) {
    deleteAdGroup(orgId: $orgId, adgroupId: $adgroupId, campaignId: $campaignId) {
      successMessage
    }
  }
`;

export const DELETE_KEYWORD = gql`
  mutation($orgId: Float!, $adgroupId: Float!, $campaignId: Float!, $keywordId: Float!) {
    deleteKeyword(orgId: $orgId, adgroupId: $adgroupId, campaignId: $campaignId, keywordId: $keywordId) {
      successMessage
    }
  }
`;

export const CREATE_SEARCH_TAB_CAMPAIGN = gql`
  mutation($orgId: Float!, $campaignRequest: CampaignDraft!) {
    createSearchTabCampaign(orgId: $orgId, campaignRequest: $campaignRequest) {
      successMessage
      data
      searchAdsErrors
    }
  }
`;

export const CREATE_TODAYS_TAB_CAMPAIGN = gql`
  mutation($orgId: Float!, $campaignRequest: CampaignDraft!) {
    createTodaysTabCampaign(orgId: $orgId, campaignRequest: $campaignRequest) {
      successMessage
      data
      searchAdsErrors
    }
  }
`;

export const CREATE_PRODUCT_PAGE_CAMPAIGN = gql`
  mutation($orgId: Float!, $campaignRequest: CampaignDraft!) {
    createProductPageCampaign(orgId: $orgId, campaignRequest: $campaignRequest) {
      successMessage
      data
      searchAdsErrors
    }
  }
`;

export const PROCESS_AUTH = gql`
  mutation($authReq: SAAuthCodeReq!) {
    processSAAuth(authReq: $authReq) {
      successMessage
      data
      content
      searchAdsErrors
    }
  }
`;

export const DELETE_CAMPAIGN_NEG_KEY = gql`
  mutation($campaignId: Float!, $keywordId: [Float!]!) {
    deleteCampaignNegKey(campaignId: $campaignId, keywordId: $keywordId) {
      successMessage
    }
  }
`;

export const DELETE_ADGROUP_NEG_KEY = gql`
  mutation($campaignId: Float!, $adgroupId: Float!, $keywordId: [Float!]!) {
    deleteAdgroupNegKey(campaignId: $campaignId, adgroupId: $adgroupId, keywordId: $keywordId) {
      successMessage
    }
  }
`;

export const APP_SETTINGS_FOR_CAMPAIGN = gql`
  mutation($adamId: Float!, $valuesForAutoCampaign: [ValuesForAutoCampaign!]!) {
    appSettingsForCampaign(adamId: $adamId, valuesForAutoCampaign: $valuesForAutoCampaign) {
      successMessage
    }
  }
`;

export const UPDATE_TRIAL_ACTIVITY = gql`
  mutation($trialActivityReq: TrialActivity!) {
    UpdateTrailActivity(trialActivityReq: $trialActivityReq) {
      successMessage
      data
    }
  }
`;

export const GENERATE_ATTR_API_KEY = gql`
  mutation($attrReq: AttIntegrationReq!) {
    generateAttrAPIKey(attrReq: $attrReq) {
      successMessage
      data
      code
    }
  }
`;

export const SAVE_CHART_PRESETS = gql`
  mutation($tabInfo: String!, $presets: String!) {
    saveChartPresets(tabInfo: $tabInfo, presets: $presets) {
      successMessage
    }
  }
`;

export const SAVE_DATATABLE_PRESETS = gql`
  mutation($tabInfo: String!, $presets: String!) {
    saveDatatablePresets(tabInfo: $tabInfo, presets: $presets) {
      successMessage
    }
  }
`;

export const SAVE_OVERVIEW_PRESETS = gql`
  mutation($tabInfo: String!, $presets: String!) {
    saveOverviewPresets(tabInfo: $tabInfo, presets: $presets) {
      successMessage
    }
  }
`;

export const SAVE_TEMPLATE_FOR_CAMPAIGN = gql`
  mutation($campaignTemplate: CampaignTemplate!) {
    saveTemplate(campaignTemplate: $campaignTemplate) {
      successMessage
    }
  }
`;

export const DRAFT_TEMPLATE_FOR_CAMPAIGN = gql`
  mutation($draftTemplate: CampaignTemplateDraft!) {
    draftTemplate(draftTemplate: $draftTemplate) {
      successMessage
    }
  }
`;

export const SAVE_CUSTOM_VIEW = gql`
  mutation(
    $_id: String!
    $customView: String!
    $tab: String!
    $datasets: String!
    $datatable: String!
    $granularity: String!
    $filters: String!
    $sortBy: String!
    $sortDesc: String!
  ) {
    saveCustomView(
      _id: $_id
      customView: $customView
      tab: $tab
      datasets: $datasets
      datatable: $datatable
      granularity: $granularity
      filters: $filters
      sortBy: $sortBy
      sortDesc: $sortDesc
    ) {
      successMessage
    }
  }
`;

export const PINNED_CUSTOM_VIEW = gql`
  mutation($_id: String!, $customView: String!, $tab: String!, $PinnedStatus: Boolean!) {
    pinnedCustomView(_id: $_id, customView: $customView, tab: $tab, PinnedStatus: $PinnedStatus) {
      successMessage
    }
  }
`;

export const INSERT_CUSTOM_VIEW = gql`
  mutation(
    $customView: String!
    $tab: String!
    $datasets: String!
    $datatable: String!
    $granularity: String!
    $filters: String!
    $sortBy: String!
    $sortDesc: String!
  ) {
    insertCustomView(
      customView: $customView
      tab: $tab
      datasets: $datasets
      datatable: $datatable
      granularity: $granularity
      filters: $filters
      sortBy: $sortBy
      sortDesc: $sortDesc
    ) {
      successMessage
      data
    }
  }
`;

export const DELETE_CUSTOM_VIEW = gql`
  mutation($_id: String!) {
    deleteCustomView(_id: $_id) {
      successMessage
    }
  }
`;

export const SAVE_SCHEDULE_REPORT = gql`
  mutation($scheduledReport: ScheduledReport!) {
    saveScheduledReport(scheduledReport: $scheduledReport) {
      successMessage
      code
    }
  }
`;

export const SAVE_LOGIN_ACTIVITY = gql`
  mutation($browser: String!) {
    loginActivity(browser: $browser) {
      successMessage
    }
  }
`;

export const DELETE_AD = gql`
  mutation($orgId: Float!, $adId: Float!, $adGroupId: Float!, $campaignId: Float!) {
    deleteAd(orgId: $orgId, adId: $adId, adGroupId: $adGroupId, campaignId: $campaignId) {
      successMessage
      searchAdsErrors
    }
  }
`;

export const UPDATE_AD = gql`
  mutation($adId: Float!, $adgroupId: Float!, $campaignId: Float!, $orgId: Float!, $adRequest: AdUpdateReq!) {
    updateAd(adId: $adId, adgroupId: $adgroupId, campaignId: $campaignId, orgId: $orgId, adRequest: $adRequest) {
      successMessage
      data
      searchAdsErrors
    }
  }
`;

export const SAVE_MODEL = gql`
  mutation($model: String!, $keywords: String, $logMessages: String) {
    saveModel(model: $model, keywords: $keywords, logMessages: $logMessages) {
      successMessage
    }
  }
`;

export const DELETE_MODEL = gql`
  mutation($modelName: String!) {
    deleteModel(modelName: $modelName) {
      successMessage
    }
  }
`;

export const CREATE_BULK_ACTION = gql`
  mutation($bulkAction: CreateBulkAction!) {
    createBulkAction(bulkAction: $bulkAction) {
      successMessage
      data
    }
  }
`;

export const CHANGE_AI_CREDITS = gql`
  mutation($TeamID: String!, $limit: Float!) {
    changeCredits(TeamID: $TeamID, limit: $limit) {
      successMessage
    }
  }
`;

export const CHANGE_AI_CREDITS_GLOBALLY = gql`
  mutation($limit: Float!) {
    changeCreditsGlobally(limit: $limit) {
      successMessage
    }
  }
`;

export const CHANGE_AI_CREDITS_GLOBALLY_LIMITED = gql`
  mutation($limit: Float!) {
    changeCreditsGloballyLimited(limit: $limit) {
      successMessage
    }
  }
`;

export const MOCK_DATA_HYDRATION = gql`
  mutation($volumes: Volumesdto!) {
    generateMockData(volumes: $volumes) {
      successMessage
      data
    }
  }
`;

export const UPSERT_STRATEGIC_VIEW = gql`
  mutation($strategy: StrategicViewIn!) {
    upsertStrategy(strategy: $strategy) {
      successMessage
      data
    }
  }
`;

export const UPSERT_TIMELINES_VIEW = gql`
  mutation($strategyTimeline: StrategicViewTimelineIn!) {
    upsertTimeline(strategyTimeline: $strategyTimeline) {
      successMessage
      data
    }
  }
`;

export const UPSERT_FILETREE = gql`
  mutation($tree: SarCustomFunctionTree!) {
    upsertFileTree(tree: $tree) {
      successMessage
    }
  }
`;

export const UPSERT_FUNCTION = gql`
  mutation($fn: SarCustomFunction!) {
    upsertCustomSarFn(fn: $fn) {
      successMessage
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation updateSubscriptionStatus($subId: String!, $status: String!) {
    updateSubscriptionStatus(subId: $subId, status: $status) {
      Status
    }
  }
`;

export const UPDATE_NOTIFY_SETTING = gql`
  mutation updateNotifySetting($notifyEnabled: Boolean!) {
    updateNotifySetting(notifyEnabled: $notifyEnabled) {
      successMessage
      code
    }
  }
`;

export const ADD_MULTIPLE_MEMBERS = gql`
  mutation($addMembersEmails: [String!]!) {
    addMultipleTeamMembers(emails: $addMembersEmails) {
      successMessage
      code
    }
  }
`;
